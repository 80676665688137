.bid-info-row {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 275px;
    margin: 0 auto;
    margin-top: 10px;

    .info-item {
        flex: 1;
        padding: 15px;
        text-align: center;
        border: 1px solid #ddd;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        background-color: #f9f9f9;
    }

    .info-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0;
        gap: 40px;

        strong {
            margin-bottom: 0;
        }
    }

    .info-row-divider {
        height: 1px;
        background-color: #ddd;
        margin: 5px 0;
    }

    .negative {
        color: #e74c3c;
    }

    .current-bid {
        color: #2ecc71;
    }
}
