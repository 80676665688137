/* CarCards.scss */

.car-cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Fixed 3 columns */
    gap: 16px;
    padding: 16px;
    margin-bottom: 70px !important;

    /* Responsive Design Adjustments */
    @media (max-width: 1200px) {
        grid-template-columns: repeat(2, 1fr); /* 2 columns on medium screens */
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr; /* 1 column on small screens */
    }
}

.car-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%; /* Ensure cards are of equal height */

    .image-container {
        position: relative;

        img {
            width: 100%;
            height: auto;
            display: block;
        }

        .time-left {
            position: absolute;
            top: 8px;
            right: 8px;
            background-color: #333;
            color: #fff;
            padding: 4px 8px;
            border-radius: 4px;
            font-size: 12px;
        }
    }

    .car-details {
        padding: 16px;
        flex: 1; /* Allow details section to grow and fill space */
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .name-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 8px;

            .car-name {
                font-size: 18px;
                font-weight: bold;
                margin: 0;
            }

            .website-name {
                font-style: italic;
                color: #666;
                font-size: 0.9rem;
                white-space: nowrap;
            }
        }

        .car-location {
            font-size: 14px;
            color: #454545;
            margin-bottom: 8px;
        }

        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;

            .star-icon {
                display: flex;
                align-items: center;
                cursor: pointer;
                font-size: 20px;
            }

            .view-button {
                flex: 1;
                padding: 8px 0;
                background-color: #4a90e2;
                color: #fff;
                border: none;
                border-radius: 4px;
                font-size: 16px;
                cursor: pointer;
                text-align: center;
                transition: background-color 0.3s;

                &:hover {
                    background-color: #357abd;
                }
            }
        }
    }
}

.car-tags {
    display: flex;
    gap: 8px;
    margin-top: 8px;
    flex-wrap: wrap;

    .car-tag {
        color: black;
        padding: 4px 8px;
        border-radius: 12px;
        font-size: 11px;
        font-weight: bold;

        &.reserve-status {
            background-color: #f8e474;
            color: black;
        }

        &.current-bid {
            color: white;
            background-color: #4a90e2;
        }

        &.higher-bid {
            background: #fa6d68; /* Red background if bid is higher than average */
            color: white;
        }

        &.lower-bid {
            background: #ABEDC6; /* Green background if bid is not higher than average */
            color: black;
        }

        .arrow-up {
            color: green;
            margin-right: 4px;
            font-weight: bold;
        }

        .arrow-down {
            color: white;
            margin-right: 4px;
            font-weight: bold;
        }
    }
}

/* Pagination Styling */
.custom-pagination {
    margin-top: 20px;
    text-align: center;
}

/* Loading Spinner Styling */
.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px; /* Adjust as needed */
}

/* Error Alert Styling */
.error-alert {
    margin-bottom: 20px;
}
