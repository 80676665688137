// Updated SCSS code with recommended changes
.car-details-container {
    display: grid;
    grid-template-columns: 300px 1fr; // Adjust width as needed
    min-height: 100vh;

    .bid-time-column {
        position: fixed;
        top: 75px; // Adjust based on your TopBar height
        left: 0;
        width: 300px;
        padding: 20px;
        background-color: #fff;
        height: calc(100vh - 75px);
        border-right: 1px solid #ddd;
    }

    .main-content {
        grid-column: 2;
        padding: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 100px;

        .car-title {
            font-size: 2rem;
            margin-bottom: 20px;
            margin-left: 20px;
        }

    }

    .auction-info {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin-bottom: 20px;
        min-height: 30px; // Ensures consistent height

        .info-row {
            display: flex;
            align-items: center;
            gap: 10px;
            white-space: nowrap; // Prevents wrapping

            &.time-left {
                min-width: 200px; // Adjust based on your needs
            }
        }

        .divider {
            width: 1px;
            height: 20px;
            background-color: #ddd;
            flex-shrink: 0; // Prevents divider from shrinking
        }

        .auction-link {
            color: blue;
            text-decoration: underline;
            cursor: pointer;
        }
    }

}

.mobile-layout {
    display: flex;
    width: 100%;
    margin-bottom: 30px;
    overflow: hidden;
    flex-direction: column;
    gap: 0rem;
    padding-top: 100px;
    .car-title {
        font-size: 2rem;
        margin-bottom: 20px;
        margin-left: 20px;
    }
}
