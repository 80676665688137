.page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background-color: #1b1b1b;
    padding-top: 3rem; // Adds space to avoid overlap with the top bar

  }
  .top-bar {
    width: 100%; 
    background-color: #2B6BE4;
    position: fixed; // Keeps the top bar fixed at the top
    top: 0;
    left: 0;
    z-index: 1000; // Ensures the top bar is above other content
    padding: 1rem;
  }

  .title{
    padding-right: 16rem; 
  }

.signup-container {
    display: flex;
    justify-content: center;
    // align-items: center;
    align-items: flex-start; // Aligns the form from the top
    margin-top: 50px;
    height: 100vh;
    background-color: #1b1b1b;
    flex-grow: 1; 
    overflow-y: auto; // Enables scrolling within the container
    padding: 2rem 0;
    width: 100%; // Ensures the form container takes up full width
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  
    form {
      width: 300px;
      padding: 2rem;
      border-radius: 10px;
      background-color: #ffffff;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      
      h2 {
        text-align: center;
        margin-bottom: 1rem;
        font-size: 1.5rem;
        font-weight: 600;
      }
  
      label {
        display: block;
        margin-bottom: 0.5rem;
        font-weight: 500;
      }
  
      input[type="email"],
      input[type="password"],
      input[type="phonenumber"],
      input[type="name"],
      input[type="text"] {
        width: 91%;
        padding: 0.75rem;
        margin-bottom: 1rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 1rem;
      }
  
      button[type="submit"] {
        width: 100%;
        padding: 0.75rem;
        background-color: #2b6be4;
        color: #ffffff;
        font-size: 1rem;
        font-weight: 600;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
  
        &:hover {
          background-color: #D6FF0F;
          color: #000
        }
      }
  
      .divider {
        display: flex;
        align-items: center;
        margin: 1.5rem 0;
  
        &::before,
        &::after {
          content: "";
          flex: 1;
          height: 1px;
          background-color: #ccc;
        }
  
        span {
          margin: 0 0.5rem;
          color: #666;
          font-size: 0.875rem;
        }
      }
  
      .social-icons {
        display: flex;
        justify-content: center;
        gap: 1rem;
  
        a {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: #f0f0f0;
          transition: background-color 0.3s;
  
          &:hover {
            background-color: #e0e0e0;
          }
  
          &.google {
            color: #db4437;
          }
  
          &.facebook {
            color: #3b5998;
          }
  
          &.linkedin {
            color: #0077b5;
          }
        }
      }
  
      .login-link {
        text-align: center;
        margin-top: 1rem;
        font-size: 0.875rem;
  
        a {
          color: #0077b5;
          text-decoration: none;
          font-weight: 500;
  
          &:hover {
            text-decoration: underline;
          }
        }
      }
      select {
        width: 100%; // Make the select field take the full width of the form
        padding: 0.75rem;
        margin-bottom: 1rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 1rem;
      }
    }

    .checkbox-container {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
      }
      
      .checkbox-container label {
        margin-left: 0.5rem;
      }
      .two-column {
        display: flex;
        gap: 1rem;
      
        > div {
          flex: 1;
        }
      }

      input[type="zip"]{
        width: 80%;
        padding: 0.75rem;
        margin-bottom: 1rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 1rem;
        margin-top: 16px;
      }

      input[type="state"]{
        width: 80%;
        padding: 0.75rem;
        margin-bottom: 1rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 1rem;
        }
  }
  