.home-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    .top-bar {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 10;
        background-color: #2B6BE4;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
    }

    .main-content {
        display: flex;
        align-items: flex-start; 
        margin-top: 60px;
        height: calc(100vh - 80px);
        overflow: hidden;
        padding-top: 15px;
        position: relative;

        .hamburger-menu {
            position: absolute;
            left: 15px; 
            top: 40px;
            z-index: 20;
            background: none;
            border: none;
            color: black;
            font-size: 1.5rem;
            cursor: pointer;
            display: flex;
            align-items: center;
        }
        .filter-sidebar {
            width: 250px;
            padding: 1rem;
            margin-top: 20px;
            background-color: #f2f2f2;
            overflow-y: auto;
            transition: transform 0.3s ease;

            max-height: 100%;
            &.collapsed {
                display: none;
            }
        }

        .search-bar {
            margin-bottom: 1rem;
        }

        .table-container {
            flex: 1;
            padding: 1rem;
            overflow-y: auto; 
            background-color: #ffffff;
            max-height: 100%; 
            
            @media (max-width: 930px) {
                margin-left: 25px;
            }
            @media (max-width: 768px) {
                margin-left: 0px;
                
            }
        }
    }

    :where(.css-dev-only-do-not-override-apn68).ant-table-wrapper .ant-table-pagination.ant-pagination {
        margin: 16px 0 50px 0;
    }

    @media (max-width: 768px) {
        .main-content {
            flex-direction: column;
            width: 100%;
            height: calc(100vh - 60px);
            margin-bottom: 30px;
            overflow: hidden;
            position: fixed;
            .icon-div {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;
                width: 100%;
                overflow: hidden;
            }
            .hamburger-menu {
                position: static; 
                margin-bottom: 10px;
                padding-top:20px;
            }
            .hamburger-menu-sort {
                position: static; 
                margin-bottom: 10px;
                padding-top:20px;
                align-self: flex-end;
            }
        }
        .filter-sidebar {
            display: none; 
        }
        .home-container .table-container{
            margin-left: 0px;
            width: 100%;
        }
    }    
}
