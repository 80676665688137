.filter-location-row {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    span {
        margin: 0 5px;
    }

    input {
        width: 100px;
    }
}

