.ppi-reports-section {
    margin-top: 2rem;
    
    .ant-table {
      background: #1f1f1f;
      
      .ant-table-thead > tr > th {
        background: #141414;
        color: white;
      }
      
      .ant-table-tbody > tr > td {
        background: #1f1f1f;
        color: #d9d9d9;
      }
      
      .ant-table-tbody > tr:hover > td {
        background: #303030;
      }
    }
    
    .anticon-file-pdf {
      color: #d9d9d9;
      
      &:hover {
        color: #1890ff;
      }
    }
  }
  