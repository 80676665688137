.page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background-color: #333; 
  }
  
  .top-bar {
    width: 100%;
    justify-content: center; 
    align-items: center; 
  }

  .title{
    padding-right: 13rem; 
  }
  
  .signin-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #1b1b1b;
    flex-grow: 1; 
  }
  
  form {
    width: 300px;
    padding: 2rem;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
    h2 {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: 600;
    }
  
    label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
    }

    input[type="email"],
    input[type="password"] {
    width: 91%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    }
  
    button {
      width: 100%;
      padding: 10px;
      margin-top: 15px;
      background-color: #007bff; 
      color: white;
      border: none;
      border-radius: 5px;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s;
      &:hover {
        background-color: #D6FF0F;
        color: #000
      }
    }
  
    .divider {
      margin: 20px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ccc;
      font-size: 14px;
  
      &::before,
      &::after {
        content: "";
        flex: 1;
        border-bottom: 1px solid #ddd;
        margin: 0 10px;
      }
    }
  
    .social-icons {
      display: flex;
      justify-content: space-around;
      margin-bottom: 15px;
  
      a {
        font-size: 24px;
        color: #666;
        text-decoration: none;
  
        &.google {
          color: #db4437;
        }
  
        &.facebook {
          color: #3b5998;
        }
  
        &.linkedin {
          color: #0077b5;
        }
      }
    }
  
    .signup-link {
        text-align: center;
        margin-top: 1rem;
        font-size: 0.875rem;
  
        a {
          color: #0077b5;
          text-decoration: none;
          font-weight: 500;
  
          &:hover {
            text-decoration: underline;
          }
        }
      }
  }
  