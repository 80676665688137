.account-page {
    background-color: #1b1b1b;
    color: white;
    min-height: 100vh;
  }
  
  .account-content {
    text-align: center;
    padding: 20px;
    margin-top: 80px
  }
  
  .profile-picture {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }

  .profile-img {
    width: 120px;      /* Set width */
    height: 120px;     /* Set height */
    border-radius: 50%; /* Make it circular */
    object-fit: cover; /* Ensure the image covers the entire area */
}

  .top-bar-title {
    flex-grow: 1;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    padding-right: 210px;

    /* Adjust styles for screens 768px wide or smaller */
    @media (max-width: 768px) {  
        font-size: 1.2rem;
        padding-right: 0px !important;
    }
}
  
  .email {
    font-size: 16px;
    margin-top: 10px;
  }
  
  .button-group {
    display: flex;
    justify-content: center;
    gap: 15px; // Add spacing between buttons
    margin-top: 20px;
  }
  
  .account-button {
    background-color: #1890ff;
    border: none;
  }
  
  .tabs {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .tab {
    color: gray;
    padding: 10px;
  }
  
  .tab.active {
    color: white;
    border-bottom: 2px solid red;
  }
  
  .transaction {
    background-color: #1b1b1b;
    padding: 20px;
    margin-top: 20px;
    border: 1px solid transparent; // Ensure no border is visible
    border-radius: 5px;
  }
  
  .transaction h2,
  .transaction p {
    margin: 0;
  }
  